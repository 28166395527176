import React from "react"
import { Parallax } from "react-scroll-parallax"

const BlockParallax = props => {
  const children = props.children
  return (
    <Parallax
      style={{ width: "100%", height: "120%" }}
      translateY={[-40, 40]}
      {...props}
    >
      {children}
    </Parallax>
  )
}

export default BlockParallax
