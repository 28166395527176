import React from "react"
import { Box, Container, Grid, Heading, Text } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"

const Timeline = ({ block }) => {
  const { title, body } = block
  return (
    <Box
      sx={{
        backgroundColor: "primary",
        color: "light",
      }}
    >
      <Container>
        <Box
          as="ul"
          sx={{
            pl: [0, 0],
            listStyleType: "none",
          }}
        >
          {block.content.map(item => (
            <Box
              as="li"
              sx={{
                borderTop: "1px solid",
                py: [2, 3],
                display: "flex",
                alignItems: "center",
                fontSize: [2, 4],
                fontWeight: "400",
                flexWrap:"wrap"
              }}
            >
              <Text sx={{ mr: [3], fontWeight:"600" }}>{item.kicker}</Text>
              <Text
                sx={{
                  minWidth: "300px",
                }}
              >
                {item.title}
              </Text>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  )
}

export default Timeline
