import React from "react"
import { Box, Container, Grid, Heading, Text } from "@theme-ui/components"
import { StructuredText } from "react-datocms"
import {
  isHeading,
  isParagraph,
  isList,
  renderRule,
} from "datocms-structured-text-utils"
import ImageGallery from "./blocks/imageGallery"
import { MagicLink } from "../utils/magicLink"
import NumbersGroup from "./blocks/numbersGroup"
import Embed from "./blocks/embed"
import TitleAndBody from "./blocks/titleAndBody"
import { OutboundLink } from "./link"
import ArrowRight from "../images/arrow.inline.svg"

const RichContentStructuredText = ({ text, theme }) => {
  const componentTheme = theme || "light"
  const light = componentTheme === "light" ? "light" : "dark"
  const dark = componentTheme === "light" ? "dark" : "light"
  return (
    <Box>
      {text.value && (
        <StructuredText
          data={text}
          renderLinkToRecord={({ record }) => {
            switch (record.__typename) {
              case "DatoCmsInternalLink":
                return (
                  <Box>
                    {<MagicLink item={record} lcoale={record.locale} />}
                  </Box>
                )
              case "DatoCmsExternalLink":
                return <MagicLink item={record} lcoale={record.locale} />
              default:
                return null
            }
          }}
          renderInlineRecord={({ record }) => {
            switch (record.__typename) {
              case "DatoCmsInternalLink":
                return (
                  <Box>
                    {<MagicLink item={record} lcoale={record.locale} />}
                  </Box>
                )
              case "DatoCmsExternalLink":
                return <MagicLink item={record} lcoale={record.locale} />
              default:
                return null
            }
          }}
          renderBlock={({ record }) => {
            switch (record.__typename) {
              case "DatoCmsImageGallery":
                return (
                  <Box mt={5} mb={5}>
                    <ImageGallery images={record.images} key={record.id} />
                  </Box>
                )
              case "DatoCmsCustomBody":
                return (
                  <Box
                    className="DatoCmsCustomBody"
                    sx={{
                      pt: ["50%"],
                      backgroundColor: "primary",
                      color: "light",
                    }}
                  >
                    <Grid columns={["1fr", "1fr", ".3fr .7fr"]}>
                      <Box></Box>
                      <Box
                        sx={{
                          borderTop: "1px solid",
                          py: [5],
                          pb: [5,9],
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            maxWidth: "414px",
                          }}
                        >
                          <Heading
                            as="div"
                            sx={{
                              h2: {
                                color: "light",
                                fontSize: [2],
                                fontWeight: "400",
                                letterSpacing: "-0.846154px",
                                lineHeight: "1.27",
                              },
                            }}
                            dangerouslySetInnerHTML={{ __html: record.title }}
                          />
                        </Box>
                        <Box
                          sx={{
                            maxWidth: "414px",
                          }}
                        >
                          <Box
                            dangerouslySetInnerHTML={{
                              __html: record.description,
                            }}
                          />
                          <Box
                            sx={{
                              pt: [6],
                            }}
                          >
                            <OutboundLink
                              sx={{
                                color: "light",
                                fontSize: [2],
                                textTransform: "uppercase",
                                display: "flex",
                                alignItems: "center",
                                svg: {
                                  ml: [2],
                                  width: "auto",
                                  height: "25px",
                                },
                              }}
                              href={record.link.url}
                            >
                              {record.link.anchor}
                              <ArrowRight />
                            </OutboundLink>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Box>
                )
              case "DatoCmsNumbersGroup":
                return (
                  <Box mt={5} mb={5}>
                    <Grid columns={["1fr", ".3fr .7fr"]}>
                      <box></box>
                      <NumbersGroup numbers={record.numbers} key={record.id} />
                    </Grid>
                  </Box>
                )
              case "DatoCmsEmbed":
                return <Embed code={record.code} fullWidth={record.fullWidth} />
              default:
                return null
            }
          }}
          customRules={[
            renderRule(
              isHeading,
              ({ adapter: { renderNode }, node, children, key }) => {
                return renderNode(
                  () => {
                    return (
                      <Text
                        as={`h${node.level}`}
                        variant={`h${node.level}`}
                        color={"primary"}
                      >
                        {children}
                      </Text>
                    )
                  },
                  { key },
                  children
                )
              }
            ),
            renderRule(
              isParagraph,
              ({ adapter: { renderNode }, node, children, key }) => {
                return renderNode(
                  () => {
                    return (
                      <Grid
                        columns={["1fr", ".3fr .7fr"]}
                        sx={{
                          my: [5,9],
                        }}
                      >
                        <Box></Box>
                        <Text as="p" mb={3} color={dark}>
                          {children}
                        </Text>
                      </Grid>
                    )
                  },
                  { key },
                  children
                )
              }
            ),
            renderRule(
              isList,
              ({ adapter: { renderNode }, node, children, key }) => {
                return renderNode(
                  () => {
                    return (
                      <Box mt={4}>
                        <Grid columns={["1fr", "1fr", ".3fr .7fr"]}>
                          <Box></Box>
                          <Box
                            as={node.style === "numbered" ? "ol" : "ul"}
                            mb={3}
                            sx={{
                              color: dark,
                              listStyle: "none",
                              margin: 0,
                              padding: 0,
                              li: {
                                borderTop: "1px solid",
                                "> div": {
                                  my: [0],
                                  display: "block",
                                },
                                py: [3],
                                alignItems: "center",
                                p: {
                                  mb: 0,
                                  fontSize:["18px",5]
                                },
                              },
                            }}
                          >
                            {children}
                          </Box>
                        </Grid>
                      </Box>
                    )
                  },
                  { key },
                  children
                )
              }
            ),
          ]}
        />
      )}
    </Box>
  )
}

export default RichContentStructuredText
