import React, { useState } from "react"
import {
  Box,
  Text,
  Heading,
  Grid,
  Input,
  Textarea,
  Checkbox,
  Flex,
  Label,
  Button,
  Container,
} from "@theme-ui/components"

import "leaflet/dist/leaflet.css"
import { i18nContext } from "../../context/i18nContext"
import axios from "axios"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import ArrowButton from "../../images/arrow.inline.svg"

const ContactForm = ({ block }) => {
  const { title, subtitle, privacyPolicyDescription } = block
  const { executeRecaptcha } = useGoogleReCaptcha()

  const isBrowser = typeof window !== "undefined"

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    agreementPrivacyCookies: false,
    urlPagina: isBrowser && window.location.href,
  })

  const [loading, setLoading] = useState(null)
  const [success, setSuccess] = useState(null)

  const sendMail = async event => {
    event.preventDefault()

    const result = await executeRecaptcha("dynamicAction")

    setLoading(true)

    const data = formData

    if (result)
      axios
        .post("/.netlify/functions/sendMail", data)
        .then(function (response) {
          setSuccess(true)
          setLoading(false)

          if (typeof window !== "undefined" && window.dataLayer !== undefined) {
            window.dataLayer = window.dataLayer || []

            window.dataLayer.push({
              event: "formSubmission",
              formType: "Contact",
            })
          }
        })
        .catch(function (error) {
          setSuccess(false)
          setLoading(false)
        })
    else {
      setLoading(false)
      setSuccess(false)
    }
  }

  return (
    <i18nContext.Consumer>
      {t => (
        <Box sx={{ py: [4, 4], backgroundColor: "primary" }}>
          <Container sx={{ pr: [3, 0], py: [0, 0] }}>
            <Grid columns={[1, 1, 1, "4.5fr 5.5fr"]} gap={[32, 32, 32, 96]}>
              <Box>
                <Heading
                  sx={{
                    mt: [0],
                    color: "light",
                    fontSize: [5, 6],
                  }}
                  variant="h2"
                >
                  {title}
                </Heading>
                <Text
                  sx={{
                    fontSize: [2, 3],
                    color: "light",
                  }}
                >
                  {subtitle}
                </Text>
              </Box>
              <Box
                as="form"
                onSubmit={sendMail}
                sx={{ backgroundColor: "primary" }}
              >
                <Grid
                  sx={{
                    flexWrap: "wrap",
                    flexDirection: "column",
                    pb: [6, 6, 0, 0],
                  }}
                  gap={[0, 0, 0, 0]}
                >
                  <Box
                    sx={{
                      pb: [4, 4, 4, 4],
                      width: ["100%", "100%"],
                    }}
                  >
                    <Input
                      onChange={e =>
                        setFormData(prevState => ({
                          ...prevState,
                          email: e.target.value,
                        }))
                      }
                      name="email"
                      type="email"
                      placeholder="Email"
                      required
                    />
                  </Box>

                  <Box
                    sx={{
                      pb: [4, 4, 4, 4],
                      width: ["100%", "100%"],
                    }}
                  >
                    <Input
                      onChange={e =>
                        setFormData(prevState => ({
                          ...prevState,
                          firstName: e.target.value,
                        }))
                      }
                      name={t.formInputTexts.firstName}
                      placeholder={t.formInputTexts.firstName}
                      required
                    />
                  </Box>
                  <Box
                    sx={{
                      pb: [4, 4, 4, 4],
                      width: ["100%", "100%"],
                    }}
                  >
                    <Input
                      onChange={e =>
                        setFormData(prevState => ({
                          ...prevState,
                          lastName: e.target.value,
                        }))
                      }
                      name={t.formInputTexts.lastName}
                      placeholder={t.formInputTexts.lastName}
                      required
                    />
                  </Box>

                  <Textarea
                    sx={{
                      fontFamily: "body",
                    }}
                    onChange={e =>
                      setFormData(prevState => ({
                        ...prevState,
                        message: e.target.value,
                      }))
                    }
                    name={t.formInputTexts.message}
                    placeholder={t.formInputTexts.message}
                    type="textarea"
                  />
                  <Box sx={{ py: 2 }}>
                    <LabeledCheckbox
                      checkedCheckbox={e => {
                        setFormData(prevState => ({
                          ...prevState,
                          agreementPrivacyCookies: e,
                        }))
                      }}
                      required={true}
                    >
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: privacyPolicyDescription,
                        }}
                      />
                    </LabeledCheckbox>
                  </Box>

                  <Box
                    sx={{
                      mt: [5],
                    }}
                  >
                    <Button
                      type="submit"
                      sx={{
                        border: "1px solid",
                        borderColor: "light",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "0px",
                        justifyContent: "space-between",
                        svg: {
                          ml: [2],
                          width: "auto",
                          height: "32px",
                        },
                      }}
                      disabled={loading}
                    >
                      {loading ? "Caricamento..." : "Invia"}
                      <ArrowButton
                        theme="dark"
                        type="submit"
                        loading={loading}
                        sx={{
                          pt: [3, 3, 3, 3],
                        }}
                      />
                    </Button>
                  </Box>

                  {success === true ? (
                    <Flex sx={{ maxWidth: "600px" }}>
                      <Heading sx={{ my: [4], color: "light" }} as="h5">
                        Richiesta inviata!
                      </Heading>
                    </Flex>
                  ) : (
                    success === false && (
                      <Flex sx={{ maxWidth: "600px" }}>
                        <Heading sx={{ my: [4], color: "light" }} as="h5">
                          Qualcosa è andato storto!
                        </Heading>
                      </Flex>
                    )
                  )}
                </Grid>
              </Box>
            </Grid>
          </Container>
        </Box>
      )}
    </i18nContext.Consumer>
  )
}

const LabeledCheckbox = ({
  children,
  defaultChecked,
  checkedCheckbox,
  ...props
}) => {
  const [checked, setChecked] = useState(defaultChecked)
  return (
    <Label
      sx={{
        display: "flex",
        alignItems: "center",
        fontSize: [0],
        color: "light",
        a: {
          color: "light",
        },
        "input:checked~.css-kydphz": {
          color: "light",
        },
        svg: {
          color: "light",
        },
        mt: [3, 3, 3, 3],
      }}
    >
      <Checkbox
        sx={{
          color: "light",
          "input:checked~&": {
            color: "light",
          },
        }}
        checked={checked}
        onChange={() => {
          checkedCheckbox(!checked)
          setChecked(!checked)
        }}
        {...props}
      />
      {children}
    </Label>
  )
}

export default ContactForm
