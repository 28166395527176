import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Box, Container, Flex, Grid, Heading } from "theme-ui"

const SponsorSection = ({ block }) => {
  const { title, sponsorsLogo } = block
  return (
    <Box sx={{
        backgroundColor:"primary",
        color:"light",
        py:[5,8]
    }}>
      <Container>
        <Grid columns={["1fr", ".5fr .5fr"]}>
          <Box>
            <Heading variant="h1" sx={{color:"light"}}>{title}</Heading>
          </Box>
          <Box>
            <Flex>
              {sponsorsLogo &&
                sponsorsLogo.map(sponsor => (
                  <Box
                    sx={{
                      mr:[5],
                      ".gatsby-image-wrapper": {
                        width: "100%",
                      },
                    }}
                  >
                    <GatsbyImage image={sponsor.gatsbyImageData} />
                  </Box>
                ))}
            </Flex>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default SponsorSection
