import React from "react"
import { Box, Container, Grid, Heading, Text } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"

const TitleAndBody = ({ block }) => {
  const { kicker, title, body, background } = block
  
  return (
    <Box sx={{
      backgroundColor: background === "dark" ? "primary" : background,
      py:[5,8]
    }}>
      <Container>
        <Text variant="kicker">{kicker}</Text>
        <Box>
          <Heading variant="h1">{title}</Heading>
        </Box>
        <Box>
          <RichContentStructuredText text={body} />
        </Box>
      </Container>
    </Box>
  )
}

export default TitleAndBody
