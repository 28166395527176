import React, { useEffect, useLayoutEffect } from "react"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { Box, Heading, Text } from "theme-ui"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

export default function AnimatedTitle({ text }) {
  const ctrls = useAnimation()

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })
 
  useEffect(() => {
    setTimeout(() => {
      var action = gsap.set(".mainTitle", {
        position: "fixed",
        paused: true,
      })

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".mainTitle h1",
          start: "top top",
          endTrigger: ".DatoCmsCustomBody",
          end: "top 50px",
          markers: false,
          pin: true,
          pinSpacing: false,
          animation: action,
          toggleActions: "play reverse play reverse",
        },
      })
    }, 300)
  }, [])

  useEffect(() => {
    if (inView) {
      ctrls.start("visible")
    }
    if (!inView) {
      ctrls.start("hidden")
    }
  }, [ctrls, inView])

  const wordAnimation = {
    hidden: {},
    visible: {},
  }

  const characterAnimation = {
    hidden: {
      opacity: 0,
      y: `0.25em`,
    },
    visible: {
      opacity: 1,
      y: `0em`,
      transition: {
        duration: 1,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
  }

  return (
    <Box className="mainTitle">
      <Heading
        as="h1"
        variant="h1"
        sx={{
          mb: [4, 4],
          fontSize: [7, 10],
          fontWeight: "400",
          color: "light",
          letterSpacing:["-1.923077px","-5.384615px"]
        }}
        aria-label={text}
        role="heading"
      >
        {text.split(" ").map((word, index) => {
          return (
            <motion.span
              ref={ref}
              aria-hidden="true"
              key={index}
              initial="hidden"
              animate={ctrls}
              variants={wordAnimation}
              transition={{
                delayChildren: index * 0.25,
                staggerChildren: 0.05,
              }}
              style={{
                display: "inline-block",
                marginRight: "0.25em",
                whiteSpace: "nowrap",
              }}
            >
              {word.split("").map((character, index) => {
                return (
                  <motion.span
                    style={{
                      display: "inline-block",
                      marginRight: "-0.05em",
                    }}
                    aria-hidden="true"
                    key={index}
                    variants={characterAnimation}
                  >
                    {character}
                  </motion.span>
                )
              })}
            </motion.span>
          )
        })}
      </Heading>
    </Box>
  )
}
