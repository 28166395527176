import React from "react"
import { Box, Container, Grid, Heading, Text } from "theme-ui"

const EventsCollection = ({ block }) => {
  const { events } = block
  return (
    <Box
      sx={{
        backgroundColor: "primary",
        pt: [5, 10],
        pb: [4],
      }}
    >
      <Container>
        <Grid columns={["1fr", "1fr 1fr"]} gap={[5, 8]}>
          {events.map(item => (
            <Box
              sx={{
                borderTop: "1px solid",
                borderColor: "light",
                color: "light",
              }}
            >
              <Heading
                sx={{
                  mt: [5],
                  mb: [6],
                  color: "light",
                  maxWidth: "414px",
                  fontWeight: "400",
                  fontSize: [5,7],
                }}
              >
                {item.title}
              </Heading>
              <Text
                as="div"
                sx={{
                  p: {
                    fontSize:[2],
                    letterSpacing:"-0.85px",
                    fontWeight: "600",
                    lineHeight:"1.273"
                  },
                }}
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            </Box>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}
export default EventsCollection
