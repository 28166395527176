import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Box, Container, Text } from "@theme-ui/components"

// Begin swiper
import SwiperCore, { Pagination, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import BlockParallax from "../blockParallax"
import { Parallax } from "react-scroll-parallax"
SwiperCore.use([Mousewheel, Pagination, A11y])
// End swiper

const ImageGallery = ({ block }) => {
  // console.log(images)
  const { images } = block
  return (
    <Box
      sx={{
        minHeight: ["150vh","100vh"],
        height: ["150vh","100vh"],
        backgroundColor: "primary",
        zIndex: 2,
      }}
    >
      <Container
        sx={{
          position: "relative",
          height: "100%",
        }}
      >
        {images.length > 1 &&
          images.map((image, index) => (
            <Box
              className={"imagegallery_" + (index + 1)}
              sx={{
                position: "absolute",
                top: index === 0 ? ["30%","10%"] : index === 1 ? ["0%","0%"] : ["70%","60%"],
                right: index === 0 ? "unset" : index === 1 ? ["0%","15%"] : ["0%"],
                left: index === 0 && "0",
                mb: 2,
                maxWidth: "570px",
                maxHeight: "545px",
                ".gatsby-image-wrapper": {
                  width: "100%",
                  maxWidth: ["315px","570px"],
                  img: {
                    height: "auto",
                    maxHeight: ["303px","545px"],
                  },
                },
              }}
            >
              <Parallax translateY={index === 0 ? [0, 30] : index === 1 ? [0, 30] : [0, 30]}>
                <BlockParallax
                  style={{ width: "100%", height: "115%" }}
                  translateY={[0, 0]}
                >
                  <GatsbyImage image={image.gatsbyImageData} alt="" />
                </BlockParallax>
              </Parallax>
            </Box>
          ))}
      </Container>
    </Box>
  )
}

export default ImageGallery
