import React from "react"
import { graphql } from "gatsby"
import { Box, Container, Heading } from "@theme-ui/components"
import Layout from "../components/layout"
import { getHomePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { useFavicon } from "../hooks/useFavicon"
import { GatsbyImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"
import TitleAndBody from "../components/blocks/titleAndBody"
import AnimatedTitle from "../components/animatedTitle"
import Timeline from "../components/blocks/timeline"
import EventsCollection from "../components/blocks/eventsCollection"
import ImageGallery from "../components/blocks/imageGallery"
import SponsorSection from "../components/blocks/sponsorSection"
import ContactForm from "../components/blocks/contactFrom"
import BlockParallax from "../components/blockParallax"

const Home = ({ data: { page, site, articles } }) => {
  const favicon = useFavicon().site.faviconMetaTags

  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getHomePath(locale),
    }
  })

  const blockIds = []

  page.content.map((block, index) => {
    if (block.menuText)
      blockIds.push({
        id: block.id,
        title: block.menuText,
      })
  })

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths} pageSections={blockIds}>
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <Box
        className="section_header"
        as="section"
        sx={{
          zIndex: 9,
          position: "relative",
          height: "100%",
          width: "100%",
          maxHeight: ["100vh"],
          ".gatsby-image-wrapper": {
            width: "100%",
            height: "100%",
            minHeight: ["300px", "350px", "600px"],
          },
        }}
      >
        <Box sx={{
          height:"100%",
          overflow:"hidden"
        }}>
          <BlockParallax
            style={{ width: "100%", height: "120vh" }}
            translateY={[-10, 5]}
          >
            <GatsbyImage image={page.heroImage.gatsbyImageData} />
          </BlockParallax>
        </Box>
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            top: "20%",
            left: 0,
            fontSize: ["8vw"],
          }}
        >
          <Container sx={{ py: [0, 0] }}>
            {/* <Heading
              as="div"
              sx={{
                height: "100%",
                display: "flex",
                alignItems: "flex-end",
                h1: {
                  mb: [3, 3],
                  fontSize: [10],
                  fontWeight: "400",
                },
              }}
              dangerouslySetInnerHTML={{ __html: page.title }}
            /> */}
            <AnimatedTitle text={page.title} />
          </Container>
        </Box>
      </Box>

      {page.content.map((block, index) => (
        <Box
          as="section"
          key={block.id}
          sx={{
            zIndex: block.model.apiKey === "image_gallery" ? 9 : 1,
            position: "relative",
          }}
          id={block.id}
        >
          {block.model.apiKey === "title_and_body" && (
            <Box>
              <TitleAndBody block={block} />
            </Box>
          )}
          {block.model.apiKey === "timeline" && (
            <Box>
              <Timeline block={block} />
            </Box>
          )}
          {block.model.apiKey === "event_collection" && (
            <Box>
              <EventsCollection block={block} />
            </Box>
          )}
          {block.model.apiKey === "image_gallery" && (
            <Box>
              <ImageGallery block={block} />
            </Box>
          )}
          {block.model.apiKey === "sponsor_section" && (
            <Box>
              <SponsorSection block={block} />
            </Box>
          )}
          {block.model.apiKey === "contact_form" && (
            <Box>
              <ContactForm block={block} />
            </Box>
          )}
        </Box>
      ))}
    </Layout>
  )
}

export default Home

export const query = graphql`
  query HomeQuery($id: String!) {
    site: datoCmsSite {
      locales
    }
    page: datoCmsHomePage(id: { eq: $id }) {
      id
      title
      heroImage {
        gatsbyImageData(
          width: 1920
          placeholder: BLURRED
          forceBlurhash: false
          imgixParams: {
            blendColor: "#000000"
            blendMode: "multiply"
            blendAlpha: 60
          }
        )
      }
      locale
      content {
        ... on DatoCmsTitleAndBody {
          ...TitleAndBodyDetails
        }
        ... on DatoCmsTimeline {
          content {
            ... on DatoCmsTitleAndBody {
              ...TitleAndBodyDetails
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsEventCollection {
          events {
            ... on DatoCmsEvent {
              title
              description
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageGallery {
          images {
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsSponsorSection {
          title
          sponsorsLogo {
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContactForm {
          kicker
          title
          subtitle
          privacyPolicyDescription
          model {
            apiKey
          }
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }

  fragment TitleAndBodyDetails on DatoCmsTitleAndBody {
    id
    background
    kicker
    menuText
    title
    body {
      links {
        ... on DatoCmsExternalLink {
          id: originalId
          url
          anchor
        }
      }
      value
      blocks {
        __typename
        ... on DatoCmsNumbersGroup {
          id: originalId
          numbers {
            legend
            float
            suffix
            prefix
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsCustomBody {
          id: originalId
          title
          description
          link {
            ... on DatoCmsExternalLink {
              id
              url
              anchor
            }
          }
          model {
            apiKey
          }
        }
      }
    }
    model {
      apiKey
    }
  }
`
